import * as universal_hooks from '../../../src/hooks.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [13,[2]],
		"/(app)/(entrypoints)/0.0.12/index": [15,[2,3]],
		"/(app)/(entrypoints)/0.0.12/[any]": [14,[2,3]],
		"/demo/resized": [86,[12]],
		"/demo/[any]": [85,[12]],
		"/(app)/error-recovery": [25,[2]],
		"/(app)/(entrypoints)/index": [16,[2,3]],
		"/loki/loki": [88],
		"/loki/[any]": [87],
		"/(app)/(entrypoints)/scfs/sctv/main": [18,[2,3]],
		"/(app)/(entrypoints)/scfs/sctv/[any]": [17,[2,3]],
		"/(app)/vizio-os": [26,[2,4]],
		"/(app)/vizio-os/apps/[id]": [28,[2,4]],
		"/(app)/vizio-os/details/[id]": [29,[2,4]],
		"/(app)/vizio-os/devtools": [30,[2,4,5]],
		"/(app)/vizio-os/devtools/experiments": [31,[2,4,5]],
		"/(app)/vizio-os/devtools/fctt": [32,[2,4,5]],
		"/(app)/vizio-os/devtools/settings": [33,[2,4,5]],
		"/(app)/vizio-os/extras": [34,[2,4,6]],
		"/(app)/vizio-os/extras/about": [35,[2,4,6]],
		"/(app)/vizio-os/extras/about/advertising-faq": [36,[2,4,6]],
		"/(app)/vizio-os/extras/about/ccpa": [37,[2,4,6]],
		"/(app)/vizio-os/extras/about/license-list": [38,[2,4,6]],
		"/(app)/vizio-os/extras/about/privacy-policy": [39,[2,4,6]],
		"/(app)/vizio-os/extras/about/terms-of-service": [40,[2,4,6]],
		"/(app)/vizio-os/extras/about/version-numbers": [41,[2,4,6]],
		"/(app)/vizio-os/extras/about/vizio-account-legacy": [42,[2,4,6]],
		"/(app)/vizio-os/extras/airplay": [43,[2,4,6]],
		"/(app)/vizio-os/extras/airplay/air-play": [44,[2,4,6]],
		"/(app)/vizio-os/extras/amazon-alexa": [45,[2,4,6]],
		"/(app)/vizio-os/extras/cast": [46,[2,4,6]],
		"/(app)/vizio-os/extras/customize-app-row": [47,[2,4,6]],
		"/(app)/vizio-os/extras/customize-app-row/include-hardware-inputs": [48,[2,4,6]],
		"/(app)/vizio-os/extras/google-assistant": [49,[2,4,6]],
		"/(app)/vizio-os/extras/notifications": [50,[2,4,6]],
		"/(app)/vizio-os/extras/notifications/itv-wf": [52,[2,4,6]],
		"/(app)/vizio-os/extras/notifications/itv": [51,[2,4,6]],
		"/(app)/vizio-os/extras/notifications/jump-view": [53,[2,4,6]],
		"/(app)/vizio-os/extras/smart-home-settings": [54,[2,4,6]],
		"/(app)/vizio-os/extras/vizio-account": [55,[2,4,6]],
		"/(app)/vizio-os/extras/voice-help": [56,[2,4,6]],
		"/(app)/vizio-os/extras/voice-help/apps-content": [57,[2,4,6]],
		"/(app)/vizio-os/extras/voice-help/entertainment-search": [58,[2,4,6]],
		"/(app)/vizio-os/extras/voice-help/navigation": [59,[2,4,6]],
		"/(app)/vizio-os/extras/voice-help/playback-control": [60,[2,4,6]],
		"/(app)/vizio-os/extras/voice-help/tv-control": [61,[2,4,6]],
		"/(app)/vizio-os/getting-started": [62,[2,4,7]],
		"/(app)/vizio-os/getting-started/account": [63,[2,4,7]],
		"/(app)/vizio-os/getting-started/apps": [64,[2,4,7]],
		"/(app)/vizio-os/getting-started/genres": [65,[2,4,7]],
		"/(app)/vizio-os/getting-started/launch": [66,[2,4,7]],
		"/(app)/vizio-os/getting-started/video": [67,[2,4,7]],
		"/(app)/vizio-os/promo/[id]": [68,[2,4]],
		"/(app)/vizio-os/search": [69,[2,4]],
		"/(app)/(entrypoints)/vizio-os/vizioos": [19,[2,3]],
		"/(app)/vizio-os/whats-new": [70,[2,4]],
		"/(app)/vizio-os/[any]": [27,[2,4]],
		"/(app)/watchfreeplus/avod/catalog": [73,[2,8,9]],
		"/(app)/(entrypoints)/watchfreeplus/avod/entry/entrypoint": [21,[2,3]],
		"/(app)/(entrypoints)/watchfreeplus/avod/entry/[any]": [20,[2,3]],
		"/(app)/watchfreeplus/avod/media": [74,[2,8,9]],
		"/(app)/watchfreeplus/avod/search": [75,[2,8,9]],
		"/(app)/watchfreeplus/avod/settings": [76,[2,8,9,10]],
		"/(app)/watchfreeplus/avod/settings/lock-by-rating": [77,[2,8,9,10]],
		"/(app)/watchfreeplus/avod/[any]": [72,[2,8,9]],
		"/(app)/watchfreeplus/channel/[id]": [78,[2,8,11]],
		"/(app)/(entrypoints)/watchfreeplus/entry/entrypoint": [23,[2,3]],
		"/(app)/(entrypoints)/watchfreeplus/entry/[any]": [22,[2,3]],
		"/(app)/watchfreeplus/error-recovery": [79,[2,8]],
		"/(app)/watchfreeplus/locked": [80,[2,8]],
		"/(app)/watchfreeplus/locked/[any]": [81,[2,8]],
		"/(app)/watchfreeplus/scanning": [82,[2,8]],
		"/(app)/watchfreeplus/scanning/[any]": [83,[2,8]],
		"/(app)/watchfreeplus/watchfreeplus": [84,[2,8]],
		"/(app)/watchfreeplus/[any]": [71,[2,8]],
		"/(app)/[any]": [24,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';